import React from 'react';
import * as styles from './BoxOption.module.css';

const BoxOption = (props) => {
  const { data, name = null, label, defaultChecked = false, onChange = undefined, disabled } = props;
  return (
    <React.Fragment>
      <input
        type={'radio'}
        name={name}
        value={data}
        className={styles.input}
        id={`${name}-${data}`}
        defaultChecked={defaultChecked}
        onChange={onChange}
        disabled={disabled}
      />
      <label
        htmlFor={`${name}-${data}`}
        className={styles.root}
        role={'presentation'}
      >
        <span className={styles.option}>{label || data}</span>
      </label>
    </React.Fragment>
  );
};

export default BoxOption;
