import React from 'react';

import AdjustItem from '../AdjustItem';
import CurrencyFormatter from '../CurrencyFormatter';
import RemoveItem from '../RemoveItem';

import * as styles from './CartItem.module.css';
import { Link } from 'gatsby';

const CartItem = (props) => {
  const { image, alt, name, slug, price, quantity, meta_data = [], onEditQuantity, onRemove } = props;

  return (
    <div className={styles.root}>
      <Link
        className={styles.imageContainer}
        role={'presentation'}
        to={`/product/${slug}/`}
      >
        <img src={image} alt={alt} />
      </Link>
      <div className={styles.itemContainer}>
        <span className={styles.name}>{name}</span>
        <div className={styles.metaContainer}>
          {
            meta_data.map(({ key, value }) => (
              <span key={`product-${slug}-cart-meta-item-${key}`}>
                {key}: {value}
              </span>
            ))
          }
        </div>
      </div>
      <div className={styles.adjustItemContainer}>
        <AdjustItem
          quantity={quantity}
          onEdit={onEditQuantity}
        />
      </div>
      <div className={styles.priceContainer}>
        <CurrencyFormatter amount={price} currency='DZD' />
      </div>
      <div className={styles.removeContainer}>
        <RemoveItem onClick={onRemove} />
      </div>
    </div>
  );
};

export default CartItem;
