import React from 'react';
import { Link } from 'gatsby';

import Button from '../Button';
import BoxOption from '../BoxOption';
import CurrencyFormatter from '../CurrencyFormatter';

import * as styles from './OrderSummary.module.css';

const OrderSummary = ({ subTotal, shippingTotal, total, shippingList, shippingLocation, shippingMode = "door", onChangeShippingMode, withAction = true, withStopDesk = true, withHomeDelivery = true, onEditShipping }) => {
  // const [coupon, setCoupon] = useState('');
  // const [giftCard, setGiftCard] = useState('');

  return (
    <div className={styles.root}>
      <div className={styles.orderSummary}>
        <span className={styles.title}>Votre commande</span>
        <div className={styles.calculationContainer}>
          <div className={styles.labelContainer}>
            <span>Sous-total</span>
            <span>
              <CurrencyFormatter amount={subTotal} currency='DZD' />
            </span>
          </div>
          <div className={styles.labelContainer}>
            <div>
              <span>Livraison</span>
              {/* <select
                name={"location"}
                onChange={onEditShipping}
                value={shippingLocation}
                className={styles.select}
              >
                <option value={""} disabled>
                  Wilaya
                </option>
                {
                  shippingList.map(({ node: { cost, location } }) => {
                    if (String(cost) === "0") return null;
                    return (
                      <option
                        value={location.code}
                        key={location.code}
                      >
                        {location.name}
                      </option>
                    );
                  })
                }
              </select> */}
              <div className={styles.shippingModeContainer}>
                <BoxOption
                  data={"door"}
                  name={"shipping-mode"}
                  label={"À domicile"}
                  defaultChecked={shippingMode === "door"}
                  onChange={onChangeShippingMode}
                  disabled={withHomeDelivery === false}
                />
                <BoxOption
                  data={"stop-desk"}
                  name={"shipping-mode"}
                  label={"Stop desk"}
                  defaultChecked={shippingMode === "stop-desk"}
                  onChange={onChangeShippingMode}
                  disabled={withStopDesk === false}
                />
              </div>
            </div>
            <span>
              <CurrencyFormatter amount={shippingTotal} currency='DZD' />
            </span>
          </div>
          {/* <div className={styles.labelContainer}>
            <span>Tax</span>
            <span>
              <CurrencyFormatter amount={0} appendZero />
            </span>
          </div> */}
        </div>
        {/* <div className={styles.couponContainer}>
          <span>Coupon Code</span>
          <FormInputField
            value={coupon}
            handleChange={(_, coupon) => setCoupon(coupon)}
            id={'couponInput'}
            icon={'arrow'}
          />
          <span>Gift Card</span>
          <FormInputField
            value={giftCard}
            handleChange={(_, giftCard) => setGiftCard(giftCard)}
            id={'couponInput'}
            icon={'arrow'}
          />
        </div> */}
        <div className={styles.totalContainer}>
          <span>Total: </span>
          <span>
            <CurrencyFormatter amount={total} currency='DZD' />
          </span>
        </div>
      </div>
      {
        withAction && (
          <div className={styles.actionContainer}>
            <Button
              href='/cart/checkout/'
              level={'primary'}
              fullWidth
            >
              Valider votre commande
            </Button>
            <div className={styles.linkContainer}>
              <Link to={'/shop/'}>Continuer vos achats</Link>
            </div>
          </div>
        )
      }
    </div>
  );
};

export default OrderSummary;
