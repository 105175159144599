// extracted by mini-css-extract-plugin
export var actionContainer = "OrderSummary-module--actionContainer--aWhjx";
export var calculationContainer = "OrderSummary-module--calculationContainer--DMofJ";
export var couponContainer = "OrderSummary-module--couponContainer--E77TJ";
export var labelContainer = "OrderSummary-module--labelContainer--vdYz4";
export var linkContainer = "OrderSummary-module--linkContainer--YnckW";
export var orderSummary = "OrderSummary-module--orderSummary--IVGgr";
export var root = "OrderSummary-module--root--oYNfA";
export var select = "OrderSummary-module--select---uroN";
export var shippingModeContainer = "OrderSummary-module--shippingModeContainer--rJKSc";
export var title = "OrderSummary-module--title--rE+6E";
export var totalContainer = "OrderSummary-module--totalContainer--SRqjI";