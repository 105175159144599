import React, { useState } from 'react';

import Icon from '../Icons/Icon';
import * as styles from './AdjustItem.module.css';

const AdjustItem = (props) => {
  const { isTransparent, onEdit, quantity = 1 } = props;
  const [qty, setQty] = useState(quantity);

  const handleOnChange = (e) => {
    const num = parseInt(e.target.value);
    setQty(num);
  };
  const onEditQuantity = (increase = true) => async () => {
    let allowUpdate = true;
    let quantity = increase === true
      ? qty + 1
      : qty === 1
        ? 1
        : qty - 1;

    if (!!onEdit) {
      allowUpdate = await onEdit(quantity);
    }
    allowUpdate && setQty(quantity);
  };
  return (
    <div
      className={`${styles.root} ${isTransparent === true ? styles.transparent : ''
        }`}
    >
      <div
        className={styles.iconContainer}
        role={'presentation'}
        onClick={onEditQuantity(false)}
      >
        <Icon symbol={'minus'}></Icon>
      </div>
      <div className={styles.inputContainer}>
        <input
          className={`${isTransparent === true ? styles.transparentInput : ''}`}
          onChange={(e) => handleOnChange(e)}
          type={'number'}
          value={qty}
          name="quantity"
          id="quantity"
        />
      </div>
      <div
        role={'presentation'}
        onClick={onEditQuantity()}
        className={styles.iconContainer}
      >
        <Icon symbol={'plus'}></Icon>
      </div>
    </div>
  );
};

export default AdjustItem;
