import { graphql, Link } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import React, { useRef } from 'react';

import Seo from '../../components/seo';
import Brand from '../../components/Brand';
import CartItem from '../../components/CartItem';
import Container from '../../components/Container';
import Footer from '../../components/Footer';
import Icon from '../../components/Icons/Icon';
import OrderSummary from '../../components/OrderSummary';
import { useCart } from '../../helpers/hooks/use-cart';
import useFreshShipping from '../../helpers/hooks/use-shipping';
import CartStorage from '../../helpers/storage';

import * as styles from './cart.module.css';
import httpRequest from '../../helpers/http';
import Alert from '../../components/alert';

const CartPage = ({ data }) => {
  const alertRef = useRef(null);
  const [state, dispatch] = useCart();
  const {
    storeShipping,
    storeDetails: {
      product_stock_url,
      store_shipping,
      store_name,
      localFile
    }
  } = data;
  const logo = getImage(localFile);
  const [shippingState] = useFreshShipping(store_shipping, storeShipping.edges);
  const onEditQuantity = (index) => async (newQuantity) => {
    dispatch({
      type: "LOADING",
      payload: true
    });
    const cart = new CartStorage();
    /**
     * Verify if the given product is already in the cart
     * then add that quantity to the new one and check its availability
     */
    const products = await cart.getAllItems();
    const currentProduct = products?.[index];

    if (!!currentProduct) {
      const { product_id = null } = currentProduct;
      const response = await httpRequest({
        url: `${product_stock_url}/${product_id}/availability?quantity=${newQuantity}`,
        requestConfig: {
          method: "GET",
        }
      });

      if (response.code === "success") {
        const newLineItems = await cart.updateItemQuantityByIndex(index, newQuantity);
        dispatch({
          type: "SET_FIELDS",
          payload: {
            ...state.data,
            lineItems: newLineItems
          }
        });
        return true;
      } else {
        dispatch({
          type: "ERROR",
          payload: {
            global: "نفد هذا المنتج من المخزن"
          } || response.errors
        });
        return false;
      }
    }

    return true;
  }
  const onRemove = (itemIndex) => async () => {
    dispatch({
      type: "LOADING",
      payload: true
    });

    const cart = new CartStorage();
    const newLineItems = await cart.removeItemByIndex(itemIndex);

    if (newLineItems !== null) {
      dispatch({
        type: "SET_FIELDS",
        payload: {
          ...state.data,
          lineItems: newLineItems
        }
      });
    }
  }
  const getLineItemsTotal = () => {
    const totalPrice = state.data?.lineItems?.reduce((acc, { product_id, quantity }) => {
      const { regular_price } = state.data.products[product_id];
      return acc + (regular_price * quantity);
    }, 0);

    return totalPrice;
  }
  const getShippingTotal = () => {
    if (state.data.shippingLines.length > 0) {
      return parseFloat(state.data.shippingLines?.[0]?.total) || 0;
    } else {
      return parseFloat(String(storeShipping.edges?.[0]?.node.cost)) || 0;
    }
  }
  const onEditShipping = async (event) => {
    const { value } = event.currentTarget;
    const selectedShippind = shippingState.data.find(({ node: { location } }) => location.code === value);

    if (selectedShippind !== undefined) {
      const cart = new CartStorage();
      const shippingLine = {
        method_id: "flat_rate",
        id: selectedShippind?.node?.zzenz_id,
        method_title: selectedShippind?.node.name,
        total: selectedShippind?.node.cost
      };
      dispatch({
        type: "SET_FIELD",
        payload: {
          key: "shippingLines",
          value: [shippingLine]
        }
      });
      await cart.updateShippingLine(shippingLine);
    }
  }
  const getShippingLocation = () => {
    const selectedShippind = storeShipping.edges.find(({ node: { zzenz_id } }) => {
      return zzenz_id === state.data.shippingLines?.[0]?.id;
    });
    return selectedShippind?.node?.location?.code;
  }
  const subTotal = getLineItemsTotal();
  const shippingTotal = getShippingTotal();
  const total = shippingTotal + subTotal;
  const alertMessage = state.errors?.global || state.message;
  const alertVariant = !!state.errors?.global ? 'danger' : 'success';
  const onCloseAlert = () => {
    alertRef.current.classList.add("fade-down");
    const timerId = setTimeout(() => {
      dispatch({
        type: "SUCCESS_WITH_ERRORS",
        payload: {
          errors: null,
          message: null
        }
      });
      clearTimeout(timerId);
    }, 600);
  };

  return (
    <div>
      <Seo
        title={"Panier"}
        pathname={"/cart/"}
        description={""}
        banner={""}
      />
      <Alert
        title={'Modifier le panier'}
        message={alertMessage}
        variant={alertVariant}
        onClose={onCloseAlert}
        reference={alertRef}
        className='alert'
      />
      <div className={styles.contentContainer}>
        <Container size={'large'} spacing={'min'}>
          <div className={styles.headerContainer}>
            <div className={styles.shoppingContainer}>
              <Link className={styles.shopLink} to={'/shop/'}>
                <Icon symbol={'arrow'}></Icon>
                <span className={styles.continueShopping}>
                  Poursuivre vos achats
                </span>
              </Link>
            </div>
            <Brand logo={logo} logoAlt={store_name} />
            <div className={styles.loginContainer}>
              <Link to={'/'}>Accueil</Link>
            </div>
          </div>
          <div className={styles.summaryContainer}>
            <h3>Panier</h3>
            <div className={styles.cartContainer}>
              <div className={styles.cartItemsContainer}>
                {
                  state.data?.lineItems?.map(({ quantity, product_id, meta_data }, index) => {
                    const product = state.data.products[product_id];
                    const productCartItem = {
                      image: product.thumbnail.src,
                      alt: product.name,
                      name: product.name,
                      price: product.regular_price,
                      slug: product.slug,
                      meta_data,
                      quantity: parseInt(quantity),
                    };

                    return (
                      <CartItem
                        key={`cart-product-item-${product_id}`}
                        onEditQuantity={onEditQuantity(index)}
                        onRemove={onRemove(index)}
                        {...productCartItem}
                      />
                    );
                  })
                }
              </div>
              <OrderSummary
                subTotal={subTotal}
                shippingTotal={shippingTotal}
                total={total}
                shippingList={shippingState.data}
                shippingLocation={getShippingLocation()}
                onEditShipping={onEditShipping}
                withHomeDelivery={false}
                withStopDesk={false}
              />
            </div>
          </div>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export const pageQuery = graphql`
  query CartPage {
    site {
      buildTime(formatString: "YYYY-MM-DD")
    }
    storeDetails: zzStoreWebsiteDetails {
      store_shipping
      product_stock_url
      store_name
      localFile {
        childImageSharp {
          gatsbyImageData(
            height: 50
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
    
    storeShipping: allZzStoreShipping(sort: {fields: [zzenz_id], order: ASC}) {
      edges {
        node {
          zzenz_id
          name
          cost
          location {
            code
            type
            name
          }
        }
      }
    }
  }
`;

export default CartPage;
